import Component from "gia/Component";
import eventbus from "gia/eventbus";

import Timer from "easytimer.js";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class showcase extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      images: [],
    };
    //////
    this.consoleStyle_def = "color: lime; background: grey";
    //////
  }

  mount() {
    const compEl = this.element;
    const images = this.ref.images;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    console.log("%cShowcase mounted", this.consoleStyle_def);

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    var mousePos;
    var mouseX = 0;
    var mouseY = 0;

    document.onmousemove = handleMouseMove;
    setInterval(getMousePosition, 1200); // setInterval repeats every X ms

    function handleMouseMove(event) {
      var dot, eventDoc, doc, body, pageX, pageY;

      event = event || window.event; // IE-ism

      // If pageX/Y aren't available and clientX/Y are,
      // calculate pageX/Y - logic taken from jQuery.
      // (This is to support old IE)
      if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX =
          event.clientX +
          ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
          ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
        event.pageY =
          event.clientY +
          ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
          ((doc && doc.clientTop) || (body && body.clientTop) || 0);
      }

      mousePos = {
        x: event.pageX,
        y: event.pageY,
      };
    }
    function getMousePosition() {
      var pos = mousePos;

      if (!pos) {
        console.log("…");
      } else {
        const count_images = images.length;
        const randomIndex = Math.floor(
          Math.random() * Math.floor(count_images)
        );

        const randomImage = images[randomIndex];
        const url_randomImage = randomImage.src;
        const size_randomImage = randomImage.dataset.size;

        /////////////////////////////////
        /////////////////////////////////
        const imageEl = document.createElement("img");

        imageEl.setAttribute("src", url_randomImage);
        imageEl.setAttribute(
          "class",
          "showcase__image showcase__image_" + size_randomImage
        );

        //////

        imageEl.setAttribute(
          "style",
          "top: " +
            (mousePos.y - 200) +
            "px; left:" +
            (mousePos.x - 200) +
            "px;"
        );

        compEl.appendChild(imageEl);

        /////////////////////////////////
        /////////////////////////////////

        const randomDeletionTime = Math.floor(Math.random() * Math.floor(10));

        var deletionTimer = new Timer({
          precision: "seconds",
          startValues: { seconds: 0 },
          target: { seconds: randomDeletionTime },
        });

        deletionTimer.addEventListener("targetAchieved", function (e) {
          compEl.removeChild(imageEl);
          console.log("%cdeleting image", this.consoleStyle_def);
        });

        deletionTimer.start();
      }
    }

    // compEl.addEventListener("mousemove", (e) => {
    //   const mouseX = e.clientX;
    //   const mouseY = e.clientY;
    //   //////
    //   const count_images = this.ref.images.length;
    //   const randomIndex = this.getRandomInt(count_images);
    //   //////
    //   const randomImage = this.ref.images[randomIndex];
    //   const url_randomImage = randomImage.src;
    //   //////
    //   const imageEl = document.createElement("img");

    //   imageEl.setAttribute("src", url_randomImage);
    //   imageEl.setAttribute("class", "showcase__image");

    //   //////
    //   const randomImageTop = this.getRandomInt(window.innerHeight);
    //   const randomImageLeft = this.getRandomInt(window.innerWidth);

    //   imageEl.setAttribute(
    //     "style",
    //     "top: " + (mouseY - 200) + "px; left:" + (mouseX - 200) + "px;"
    //   );

    //   compEl.appendChild(imageEl);

    //   var deletionTimer = new Timer({
    //     precision: "seconds",
    //     startValues: { seconds: 0 },
    //     target: { seconds: 5 },
    //   });

    //   deletionTimer.addEventListener("targetAchieved", function (e) {
    //     compEl.removeChild(imageEl);
    //     console.log("%cdeleting image", this.consoleStyle_def);
    //   });

    //   deletionTimer.start();
    // });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    this.setState({});

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("event", (args) => {});
  }

  stateChange(changes) {}

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { showcase };
