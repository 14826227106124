import Component from "gia/Component";
import eventbus from "gia/eventbus";

import Timer from "easytimer.js";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class clock extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      output: null,
    };
    //////
    this.consoleStyle_def = "color: lime; background: grey";
  }

  mount() {
    const compEl = this.element;
    const output = this.ref.output;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    console.log("%cClock mounted", this.consoleStyle_def);

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    this.setState({});

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const timer = new Timer();

    timer.start({
      precision: "seconds",
      startValues: {
        hours: 10,
      },
      target: { hours: 12 },
    });

    timer.addEventListener("secondsUpdated", function (e) {
      const currentTime = timer.getTimeValues().toString();
      //////
      output.innerHTML = currentTime;
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("event", (args) => {});
  }

  stateChange(changes) {}
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { clock };
