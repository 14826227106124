import Swup from "swup";
import SwupGiaPlugin from "@swup/gia-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";

import loadComponents from "gia/loadComponents";
import removeComponents from "gia/removeComponents";
import eventbus from "gia/eventbus";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import { get_elementStyleValue } from "./utilities/get_elementStyleValue";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

const consoleStyle_def = "color: lime; background: grey";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import { clock } from "../components/clock/clock";
import { showcase } from "../components/showcase/showcase";

/////////////////////////////////
/////////////////////////////////

const appComponents = {
  clock,
  showcase,
};

// loadComponents({

// });

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

const swup = new Swup({
  plugins: [
    new SwupGiaPlugin({ components: appComponents, log: true }),
    new SwupPreloadPlugin(),
  ],
});

/////////////////////////////////
/////////////////////////////////

swup.on("contentReplaced", () => {
  console.log("%cSwup: content replaced", consoleStyle_def);
});

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
